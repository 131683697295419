@use "./import/index" as g;


/* *
 *
 *  base
 *
 * */

html {
  -webkit-overflow-scrolling: touch;
}

/*--------------------------------------------------------------------------
   base
---------------------------------------------------------------------------*/
body {
  line-height: 1.5;
  display: block;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: normal;
  @include g.font-smoothing;
  word-wrap : break-word;
  overflow-wrap : break-word;
  text-size-adjust: 100%;
  color: g.$color-black;
  letter-spacing: 0.1em;
  background: g.$color-white;
  @include g.font-size(14);
}


/*--------------------------------------------------------------------------
   base link
---------------------------------------------------------------------------*/
a {
  color: g.$color-black;
  text-decoration: none;
  word-break: break-word;
}

/*--------------------------------------------------------------------------
   img
---------------------------------------------------------------------------*/
picture {
  display: block;
}
img, svg {
  vertical-align: middle;
  width: auto;
  max-width: 100%;
  height: auto;
}


/*--------------------------------------------------------------------------
   u-sp
---------------------------------------------------------------------------*/
.u-sp{
   display: none;
   @include g.media-sp {
      display: block;
   }
}


/*--------------------------------------------------------------------------
   l-inners
---------------------------------------------------------------------------*/
.l-inner{
   max-width: g.$pc-width;
   padding-left: g.vw(120,1440);
   padding-right: g.vw(120,1440);
   margin-left: auto;
   margin-right: auto;
   @include g.media-pc-wide {
      padding-left:  120px;
      padding-right: 120px;
   }
   @include g.media-sp {
      padding-left: g.vw(20);
      padding-right: g.vw(20);
   }
   &--s{
      max-width: g.$pc-width-min;
      padding-left: 0;
      padding-right: 0;
      @include g.media-pc-min {
         max-width: none;
         padding-left: g.vw(120,1440);
         padding-right: g.vw(120,1440);
      }
      @include g.media-sp {
         padding-left: g.vw(20);
         padding-right: g.vw(20);
      }
   }
}


/*--------------------------------------------------------------------------
   l-footer
---------------------------------------------------------------------------*/
.l-footer{
   @include g.font-size(12);
   a{
      transition: all g.$fadein-duration g.$fadein-ease;
   }
   .inner{
      padding-top: g.vw(30,1440);
      padding-bottom: g.vw(30,1440);
      border-top: solid 1px g.$color-gray;
      display: flex;
      justify-content: space-between;
      @include g.media-sp {
         padding-top: g.vw(30);
         padding-bottom: g.vw(30);
      }
   }
   .link{
      a{
         &:hover{
            color: g.$color-gray2;
         }
      }
   }
   .twitter{
      color: g.$color-gray2;
   }
}



/*--------------------------------------------------------------------------
   l-header
---------------------------------------------------------------------------*/
.l-header{
   padding: g.vw(100,1440) 0;
   @include g.media-sp {
      padding: g.vw(60) 0;
   }
   .logo{
      width: 200px;
      @include g.media-sp {
         width: g.vw(200);
      }
   }
}
