@use "./var" as *;
@use "./functions" as *;


/*--------------------------------------------------------------------------
  breakpoint
---------------------------------------------------------------------------*/
@mixin media-pc {
  @media screen and (min-width: $breakpoint+1){
    @content;
  }
}
@mixin media-pc-wide {
  @media screen and (min-width: $pc-breakpoint){
    @content;
  }
}
@mixin media-pc-min {
  @media screen and (max-width: $pc-breakpoint-min){
    @content;
  }
}
@mixin media-sp {
  @media screen and (max-width: $breakpoint){
    @content;
  }
}

/*--------------------------------------------------------------------------
  scrollin
---------------------------------------------------------------------------*/
@mixin inview-hide {
  &[data-inview="hide"] {
    @content;
  }
}
@mixin inview-show {
  &[data-inview="show"] {
    @content;
  }
}

/*--------------------------------------------------------------------------
  text
---------------------------------------------------------------------------*/
// font-smoothing
@mixin font-smoothing($bool:true) {
  @if $bool == true{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @if $bool == false{
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// font-metrics
@mixin font-metrics($bool:true) {
  @if $bool == true{
    font-feature-settings : "palt" 1;
  }
  @if $bool == false{
    font-feature-settings: normal;
  }
}

// font-size
@mixin font-size($px) {
  @include media-sp {
    font-size: vmin($px);
  }
  @include media-pc {
    font-size: rem($px);
  }
}

@mixin text-stroke($color: $color-black, $px: 2) {
  position: relative;
  font-weight: $weight-bold;
  .fore {
    position: relative;
    z-index: 5;
  }
  .back {
    text-stroke: ($px)+px $color;
    -webkit-text-stroke: ($px)+px $color;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
}

@mixin text-shadow($x:2, $y:2, $blur:0, $color: $color-black) {
  text-shadow: ($x)+px ($y)+px ($blur)+px $color;
}


/*--------------------------------------------------------------------------
  px-vmin
---------------------------------------------------------------------------*/
// margin
@mixin margin($top-px: 0, $right-px: 0, $bottom-px: 0, $left-px: 0) {
  @include media-sp {
    margin: vmin($top-px) vmin($right-px) vmin($bottom-px) vmin($left-px);
  }
  @include media-pc {
    margin: ($top-px)+px ($right-px)+px ($bottom-px)+px ($left-px)+px;
  }
}

// padding
@mixin padding($top-px: 0, $right-px: 0, $bottom-px: 0, $left-px: 0) {
  @include media-sp {
    padding: vmin($top-px) vmin($right-px) vmin($bottom-px) vmin($left-px);
  }
  @include media-pc {
    padding: ($top-px)+px ($right-px)+px ($bottom-px)+px ($left-px)+px;
  }
}

//width
@mixin width($px) {
  @include media-sp {
    width: vmin($px);
  }
  @include media-pc {
    width: ($px)+px;
  }
}

//height
@mixin height($px) {
  @include media-sp {
    height: vmin($px);
  }
  @include media-pc {
    height: ($px)+px;
  }
}

//position
@mixin position($top-px: auto, $right-px: auto, $bottom-px: auto, $left-px: auto) {
  @if $top-px != "auto"{
    @include media-sp {
      top: vmin($top-px);
    }
    @include media-pc {
      top: ($top-px)+px;
    }
  }
  @if $right-px != "auto"{
    @include media-sp {
      right: vmin($right-px);
    }
    @include media-pc {
      right: ($right-px)+px;
    }
  }
  @if $bottom-px != "auto"{
    @include media-sp {
      bottom: vmin($bottom-px);
    }
    @include media-pc {
      bottom: ($bottom-px)+px;
    }
  }
  @if $left-px != "auto"{
    @include media-sp {
      left: vmin($left-px);
    }
    @include media-pc {
      left: ($left-px)+px;
    }
  }
}


/*--------------------------------------------------------------------------
  pseudo
---------------------------------------------------------------------------*/
@mixin pseudo($width:100%,$height: 100%){
  content: "";
  display: block;
  width: $width;
  height: $height;
}
