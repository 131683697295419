@use "./import/index" as g;
$pc-fade-pos: 80px;
$sp-fade-pos: g.vw(60);

/* *
 *
 *  index
 *
 * */

$pc-width : 1440;

.l-page.page-index{
  .l-main{
    padding-bottom: g.vw(120,$pc-width);
    @include g.media-pc-wide {
      padding-bottom: 120px;
    }
    @include g.media-sp {
      padding-bottom: g.vw(80);
    }
  }

  /*===================
    .mv
  ===================*/
  .mv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: g.$color-yellow;
    opacity: 0;
    // transform: translateY(50px);
    transition: all 1200ms 500ms g.$fadein-ease;
    &.is-show{
      opacity: 1;
      // transform: translateY(0);
    }

    h1{
      @include g.media-sp {
        width: g.vw(200);
      }
    }
  }

  /*===================
    .shop
  ===================*/
  .shop{
    .list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: g.vw(40,$pc-width);
      letter-spacing: 0.05em;
      @include g.media-pc-wide {
        margin-top: 40px;
      }
      @include g.media-sp {
        margin-top: g.vw(50);
      }
      > li{
        width: 47.5%;// 570 / 1200
        margin-top: g.vw(80,$pc-width);
        h2,.link,.img{
          transform: translateY($pc-fade-pos);
          opacity: 0;
          transition: opacity g.$fadein-duration g.$fadein-ease,transform g.$fadein-duration g.$fadein-ease;
          @include g.media-sp {
            transform: translateY($sp-fade-pos);
          }

        }
        .img{ transition-delay: 50ms;}
        h2{ transition-delay: 100ms;}
        .link{ transition-delay: 150ms;}
        &.is-show{
          h2,.link,.img{
            opacity: 1;
            transform: translateY(0);
          }
        }
        @include g.media-pc-wide {
          margin-top: 80px;
        }
        @include g.media-sp {
          margin-top: g.vw(80);
          width: 100%;
          text-align: center;
          &:first-child{
            margin-top: 0;
          }
        }

        h2{
          margin-top: g.vw(30,$pc-width);
          letter-spacing: 0.05em;
          @include g.font-size(16);
          @include g.media-pc-wide {
            margin-top: 30px;
          }
          @include g.media-sp {
            margin-top: g.vw(30);
          }
        }
        .label{
          display: block;
          margin-bottom: 5px;
          @include g.font-size(10);
        }
        .link{
          display: flex;
          flex-wrap: wrap;
          @include g.font-size(12);
          margin-top: g.vw(15,$pc-width);
          @include g.media-pc-wide {
            margin-top: 15px;
          }
          @include g.media-sp {
            margin-top: g.vw(15);
            justify-content: center;
          }
          li{
            margin-right: 12px;
            @include g.media-sp {
              margin: 0 g.vw(6);
            }
            a{
              transition: color g.$fadein-duration g.$fadein-ease;
              &:hover{
                color: g.$color-gray2;
              }
            }
          }
        }
      }
    }
  }

  /*===================
    .company
  ===================*/
  .company{
    margin-top: g.vw(140,$pc-width);
    @include g.media-pc-wide {
      margin-top: 140px;
    }
    @include g.media-sp {
      margin-top: g.vw(80);
    }
    .list,.btn{
      opacity: 0;
      transition: transform g.$fadein-duration g.$fadein-ease, opacity g.$fadein-duration g.$fadein-ease;
      transform: translateY($pc-fade-pos);
      @include g.media-sp {
        transform: translateY($sp-fade-pos);
      }

      &.is-show{
        opacity: 1;
        transform: translateY(0);
      }
    }
    .list{
      border-bottom: solid 1px g.$color-gray;
      dl{
        border-top: solid 1px g.$color-gray;
        display: flex;
        dt,dd{
          padding: g.vw(32,$pc-width) 0;
          @include g.media-pc-wide {
            padding: 32px 0;
          }
          @include g.media-sp {
            padding: g.vw(20) 0;
          }
          .notion{
            display: block;
            @include g.font-size(12);
          }
        }
        dt{
          width: 150px;
          @include g.media-sp {
            width: g.vw(95);
          }
        }
        dd{
          @include g.media-sp {
            width: calc(100% - #{g.vw(95)});
          }
        }
      }
    }
    .btn{
      margin-top: g.vw(120,$pc-width);
      display: flex;
      justify-content: space-between;
      @include g.media-pc-wide {
        margin-top: 120px;
      }
      @include g.media-sp {
        display: block;
      }

      li{
        width: 46.28%;
        @include g.media-sp {
          width: 100%;
          margin-top: g.vw(20);
        }
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 80px;
          background-color: g.$color-yellow;
          color: g.$color-white;
          transition: background-color g.$fadein-duration g.$fadein-ease,color g.$fadein-duration g.$fadein-ease;
          @include g.font-size(18);
          @include g.media-sp {
            height: g.vw(80);
          }
          &:hover{
            background-color: #d7ca85;
            color: g.$color-white;
          }
        }
      }
    }
  }

  /*===================
    .shop
  ===================*/

}
