@use "./import/index" as g;

/* *
 *
 *  privacy
 *
 * */

$pc-width : 1440;

.l-page.page-privacy{
  .l-main{
    padding-bottom: g.vw(244,$pc-width);
    @include g.media-pc-wide {
      padding-bottom: 244px;
    }
  }
  /*===================
    .ttl-page
  ===================*/
  .ttl-page{
    margin-top: g.vw(60,$pc-width);
    @include g.media-sp {
      margin-top: 0;
    }
  }

  /*===================
    .txt
  ===================*/
  .txt{
    margin-top: g.vw(130,$pc-width);
    padding-top: g.vw(60,$pc-width);
    border-top: solid 1px g.$color-gray;
    line-height: 1.75;
    @include g.media-sp {
      margin-top: g.vw(60);
      padding-top: g.vw(60);
    }
    p,ol{
      margin-top: g.vw(80,$pc-width);
      @include g.media-sp {
        margin-top: g.vw(80);
      }
      &:first-child{
        margin-top: 0;
      }
    }
    ol{
      padding-left: 1.4rem;
      list-style-type: decimal;
    }
    ul{
      padding-left: 1.3rem;
      list-style-type: disc;
    }
  }
}
